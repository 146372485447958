import GLightbox from 'glightbox';

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelectorAll('[data-lightbox]').length) {
    return;
  }
  GLightbox({
    selector: '[data-lightbox]'
  });
});
