import 'mdn-polyfills/NodeList.prototype.forEach';
import FormMultiSelect from './FormMultiselect';

document.addEventListener('DOMContentLoaded', () => {
  const multiSelects = document.querySelectorAll('[data-form-multiselect]');
  if (!multiSelects.length) {
    return;
  }
  multiSelects.forEach((element) => {
    const multiSelect = new FormMultiSelect(element);
    multiSelect.mount();
  });
});
