/**
 * Name: Socials
 * Description: Use navigator share when available
 */

export default class Socials {
  constructor (element) {
     this.element = element
  }

  async handleShare () {
    const data = JSON.parse(this.share.dataset.socialsShare);

    try {
      await navigator.share(data);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  }

  async handleCopy () {
    navigator.clipboard
      .writeText(window.location.href);
    this.feedback(this.copy, 'Lien copié');
  }

  feedback (item, text) {
    item.setAttribute('data-feedback', text);

    setTimeout(() => {
      item.removeAttribute('data-feedback');
    }, 2000);
  }

  mount () {
    if (navigator.canShare) {
      this.items = this.element.querySelectorAll('[data-socials-item]');
      this.share = this.element.querySelector('[data-socials-share]');
      this.handleShare = this.handleShare.bind(this);

      this.items.forEach((item) => {
        if (item.dataset.socialsItem === "share") {
          item.removeAttribute('hidden');
        }
      });

      this.share.addEventListener('click', this.handleShare);
    }

    this.copy = this.element.querySelector('[data-socials-copy]');
    if (this.copy) {
      if (window.isSecureContext) {
        this.handleCopy = this.handleCopy.bind(this);
        this.copy.addEventListener('click', this.handleCopy);
      } else {
        this.copy.remove();
      }
    }
  }

  unmount () {
    this.copy.removeEventListener('click', this.handleCopy);

    if (navigator.canShare) {
      this.share.eventEventListener('click', this.handleShare);

      this.items.forEach((item) => {
        if (item.dataset.socialsItem === "share") {
          item.setAttribute('hidden', '');
        }
      });

      delete this.handleShare;
      delete this.items;
      delete this.copy;
      delete this.share;
    }
  }
}
