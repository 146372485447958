/**
 * Name: Newsletter
 * Description: Handle Sendinble Newsletter
 */

export default class Newsletter {
  constructor (element) {
    this.element = element;
    this.feedback = this.element.querySelector('[data-newsletter-feedback]');
    this.feedbackText = this.feedback.querySelector('[data-newsletter-feedback-text]');

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateFeedback (status, text) {
    this.feedback.setAttribute('hidden', '');
    this.feedback.classList.remove('c-alert--error');
    this.feedback.classList.add(`c-alert--${status}`);
    this.feedbackText.innerHTML = text;
    this.feedback.removeAttribute('hidden');
  }

  handleSubmit (e) {
    e.preventDefault();

    const formData = new FormData(this.element);

    this.element.classList.add('is-loading');


    window.fetch(this.element.action, {
        method: this.element.method,
        cache: 'default',
        body: formData,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }

            return response.json();
        })
        .then((json) => {
          this.updateFeedback('success', json.message);
          this.element.querySelectorAll('[type="submit"]').forEach((submit) => {
            submit.disabled = true;
          });
          this.element.classList.remove('is-loading');
        })
        .catch((e) => {
          console.log({e});
          this.updateFeedback('error', 'Une erreur s\'est produite, veuillez réessayer plus tard');
          this.element.classList.remove('is-loading');
        });
  }

  mount () {
    this.element.addEventListener('submit', this.handleSubmit);
  }

  unmount () {
    this.element.removeEventListener('submit', this.handleSubmit);
  }
}
