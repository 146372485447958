/**
 * Name: FormMultiselect
 * Description: Write_description_here
 */

import 'mdn-polyfills/NodeList.prototype.forEach';

export default class FormMultiselect {
  constructor(element) {
     this.element = element
     this.control = this.element.querySelector('[data-form-multiselect-control]');
     this.menu = this.element.querySelector('[data-form-multiselect-menu]');
     this.counter = this.control.querySelector('[data-form-multiselect-counter]');
     this.inputs = this.menu.querySelectorAll('input');
     this.state = false;
     this.total = this.inputs.length;
     this.translations = {
      empty: ``,
      singular: `{{count}} selectionné sur ${this.total}`,
      plural: `{{count}} selectionnés sur ${this.total}`
     }

     this.handleFocusOut = this.handleFocusOut.bind(this);
     this.handleControlClick = this.handleControlClick.bind(this);
     this.handleInputClick = this.handleInputClick.bind(this);
     this.handleDocumentKeydown = this.handleDocumentKeydown.bind(this);
  }

  handleControlClick (e) {
    this.state ? this.collapse() : this.expande();
  }

  handleFocusOut (e) {
    if (!this.element.contains(e.relatedTarget)) {
      this.collapse();
    }
  }

  handleInputClick (e) {
    this.updateCounter();
  }

  handleDocumentKeydown (e) {
    let isEscape = false;
    if ("key" in e) {
      isEscape = e.key == "Escape" || e.key == "Esc";
    } else {
      isEscape = e.keyCode == 27;
    }
    if (isEscape) {
      this.collapse();
    }
  }

  getTranslation (count) {
    let translation;

    if(count === 0) {
      translation = this.translations.empty;
    } else if(count === 1) {
      translation = this.translations.singular;
    } else if( count > 1) {
      translation = this.translations.plural;
    }

    return translation.replace('{{count}}', count);
  }

  expande () {
    this.control.setAttribute('aria-expanded', "true");
    this.state = true;
  }

  collapse () {
    this.control.setAttribute('aria-expanded', "false");
    this.state = false;
  }

  updateCounter () {
    this.counter.innerHTML = this.getTranslation(Array.from(this.inputs).filter(input => input.checked).length);
  }

  mount () {
    this.control.setAttribute('aria-expanded', "false");
    this.control.setAttribute('aria-controls', this.menu.getAttribute('id'));
    this.menu.setAttribute('aria-labelledby', this.control.getAttribute('id'));
    this.menu.setAttribute('role', "group");
    this.menu.setAttribute('tabindex', "0");

    this.inputs.forEach(input => {
      input.addEventListener('click', this.handleInputClick);
    });

    this.element.addEventListener('focusout', this.handleFocusOut);
    this.control.addEventListener('click', this.handleControlClick);
    document.addEventListener('keydown', this.handleDocumentKeydown);

    this.updateCounter();

    this.element.classList.add('is-mounted');
  }

  unmount () {
    this.control.removeAttribute('aria-expanded');
    this.control.removeAttribute('aria-controls');
    this.menu.removeAttribute('aria-labelledby');
    this.menu.removeAttribute('role');
    this.menu.removeAttribute('tabindex');

    this.inputs.forEach(input => {
      input.removeEventListener('click', this.handleInputClick);
    });

    this.element.removeEventListener('focusout', this.handleFocusOut);
    this.control.removeEventListener('click', this.handleControlClick);
    this.control.removeEventListener('keydown', this.handleDocumentKeydown);

    this.element.classList.remove('is-mounted');
  }
}
