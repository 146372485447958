import 'mdn-polyfills/NodeList.prototype.forEach';
import Socials from './Socials';

document.addEventListener('DOMContentLoaded', () => {
  const socialsItems = document.querySelectorAll('[data-socials]');
  if (!socialsItems.length) {
    return;
  }
  socialsItems.forEach((element) => {
    const socials = new Socials(element);
    socials.mount();
  });
});
